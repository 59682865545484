import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb'

export default function PageRules() {
    const [tab, setTab] = useState('step1rulesTab');
    const handleTabClick = (tabId) => {
        setTab(tabId);
      };

    return (
        <>
            <Breadcrumb className="" breadcrumbIcon="GlobeEuropeAfricaSvg" breadcrumbHeading="Rules" />
            <div className="container-lg cl-custome3">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="common-box">

                            {/* Step 1 & Step 2 Rules btn */}
                            <div className="common-tabs-bx mb-4">
                                <div className={`ctb-item ${tab === 'step1rulesTab' ? 'active' : ''}`} onClick={() => handleTabClick('step1rulesTab')}>Step 1</div>
                                <div className={`ctb-item ${tab === 'step2rulesTab' ? 'active' : ''}`} onClick={() => handleTabClick('step2rulesTab')}>Step 2</div>
                            </div>

                            {/* Step 1 Rules btn */}
                            <div className={`row ${tab === 'step1rulesTab' ? '' : 'd-none'} `} id="step1rulesTab">
                                <div className="legal-content">
                                    <div className="legal-heading">We know you can make money :</div>
                                    <p>Traders must achieve the profit target of their respective challenge, without worrying about time limits to stay compliant with the rules.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Risk Management is Must :</div>
                                    <p>In order to better provide you with products and services offered, FundedFirm may collect personally identifiable information, such as your:</p>
                                    <ul className='ul-dick'>
                                        <li>Maximum Daily Drawdown - 3%</li>
                                        <li>Maximum Overall Drawdown -6%</li>
                                    </ul>
                                    <p>The Trading Period is Unlimited and if the Account is Inactive for 30 days it will be Disabled.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Leverage is Important :</div>
                                    <p>We believe in letting you trade without worrying about leverage. That’s why we offer</p>
                                    <ul className='ul-dick'>
                                        <li>Forex: 1:100</li>
                                        <li>Metals: 1:100</li>
                                        <li>Indices: 1:100</li>
                                        <li>Energies: 1:100</li>
                                        <li>Crypto: 1:50</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Weekend and Overnight Holding :</div>
                                    <p>We Want You to make Money , Hold Till Whenever You Wish, We Don’t Mind.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">News Trading Allowed :</div>
                                    <p>News trading is Allowed , Do Fundamental analysis and Trade the way You want.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Hedging Rule :</div>
                                    <ul className='ul-dick'>
                                        <li>You cannot open buy-and-sell positions in the same currency pair.</li>
                                        <li>For example, At 1940-XAUUSD Buy, At1960-XAUSD Sell.</li>
                                        <li>You cannot have both a buy position and a sell position open at the same time.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Profit Sharing :</div>
                                    <ul className='ul-dick'>
                                        <li>1st month - 90% Payout of Profit will Be Given</li>
                                        <li>2nd month - 95% Payout of Profit will Be Given</li>
                                        <li>3rd month - 100% Payout of Profit will Be Given</li>
                                    </ul>
                                </div>
                                <p>*A profit of Minimum 1% of the account Size is important to apply for a Payout request.</p>
                                <div className="legal-content">
                                    <div className="legal-heading">1. Minimum Trading days :</div>
                                    <ul className='ul-dick'>
                                        <li>To complete each stage of our evaluation, you must trade on at least 5 separate days. A trading day is recorded when you execute at least one trade, regardless of how long it’s held. Holding trades overnight won’t count as additional trading days.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">2. Profit Target:</div>
                                    <ul className='ul-dick'>
                                        <li>Achieve the 10% profit target by closing all your trades and following the trading rules within the designated time period to demonstrate your trading skills.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">3. Understanding Daily Drawdown Rules (Trailing Balance)</div>
                                    <ul className='ul-dick'>
                                        <li>The daily drawdown limit is the most you can lose in a day, set at 3% of your trailing balance (previous day's balance + booked profits).</li>
                                        <li>For example, with a $500.00 account Balance, the limit is 3%, or $15.00. If you make a $8.00 profit on Day 1, your balance becomes $508.00, and the new limit for Day 2 is 3% of $508.00 or $15.24</li>
                                        <li>After earning $23 more on Day 2, your balance updates to $531.00, raising the limit to $15.93</li>
                                        <li>But if your losses on Day 2 sum to $20.00, exceeding the $15.93 limit, your account will be disabled. Always track both running and booked losses to stay within limits.</li>
                                        <li>Always track both running and booked losses to stay within limits.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">4. Understanding Overall Drawdown Rules (Trailing Balance)</div>
                                    <ul className='ul-dick'>
                                        <li>The overall drawdown limit is the maximum amount you can lose before your account is disabled, set at 6% of the trailing balance until it goes below initial balance.</li>
                                        <li>On Day 1, you make a $20.00 profit, increasing your balance to $520.00. The drawdown limit adjusts to 6% of $520.00, which is $31.20.</li>
                                        <li>On Day 2, you lose $15.00 (&lt; $31.20). Your account remains active, and the new drawdown limit is 6% of $505.00 ($30.30).</li>
                                        <li>On Day 3, you lose another $15.00. Your balance drops to $490.00. Now, for all your trades, your max drawdown is 6% of $500.00 ($30.00), until you close a day in profit, and exceeding it will lead to disablement.</li>
                                        <li>The overall drawdown rule applies to both running and closed trades, so monitor your balance carefully.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">5. Risk Management & Discipline :</div>
                                    <ul className='ul-dick'>
                                        <li>Challenge Account: No single trade should contribute to more than 30% of the total profit target. Real Account: This applies to the requested payout amount.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">5(1). Violation Consequences :</div>
                                    <ul className='ul-dick'>
                                        <li>Any breach of the established trading rules will lead to an immediate account suspension and permanent disablement.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Rationale :</div>
                                    <p>This rule encourages traders to maintain steady and predictable trading volumes, which helps in managing risk and ensuring that profits are earned through consistent trading behavior. It also offers room for growth, allowing traders to scale their volumes over time while maintaining discipline.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Please note :</div>
                                    <p>Hitting either the daily loss limit or the maximum loss limit will result in a permanent account breach.</p>
                                </div>
                            </div>


                            {/* Step 2 Rules btn */}
                            <div className={`row ${tab === 'step2rulesTab' ? '' : 'd-none'} `} id="step2rulesTab">
                                <div className="legal-content">
                                    <div className="legal-heading">We know you can make money :</div>
                                    <p>Traders must achieve the profit target of their respective challenges, without worrying about time limits to stay compliant with the rules.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Risk Management is Must :</div>
                                    <p>In order to better provide you with products and services offered, FundedFirm may collect personally identifiable information, such as your:</p>
                                    <ul className='ul-dick'>
                                        <li>Maximum Daily Drawdown - 5%</li>
                                        <li>Maximum Overall Drawdown -10%</li>
                                    </ul>
                                    <p>The Trading Period is Unlimited and if the Account is Inactive for 30 days it will be Disabled.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Leverage is Important :</div>
                                    <p>We believe in letting you trade without worrying about leverage. That’s why we offer</p>
                                    <ul className='ul-dick'>
                                        <li>Forex: 1:100</li>
                                        <li>Metals: 1:100</li>
                                        <li>Indices: 1:100</li>
                                        <li>Energies: 1:100</li>
                                        <li>Crypto: 1:50</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Weekend and Overnight Holding :</div>
                                    <p>We Want You to make Money , Hold Till Whenever You Wish, We Don’t Mind.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">News Trading Allowed :</div>
                                    <p>News trading is Allowed , Do Fundamental analysis and Trade the way You want.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Hedging Rule :</div>
                                    <ul className='ul-dick'>
                                        <li>You cannot open buy-and-sell positions in the same currency pair.</li>
                                        <li>For example, At 1940-XAUUSD Buy, At1960-XAUSD Sell.</li>
                                        <li>You cannot have both a buy position and a sell position open at the same time.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Profit Sharing :</div>
                                    <ul className='ul-dick'>
                                        <li>1st month - 90% Payout of Profit will Be Given</li>
                                        <li>2nd month - 95% Payout of Profit will Be Given</li>
                                        <li>3rd month - 100% Payout of Profit will Be Given</li>
                                    </ul>
                                </div>
                                <p>*A profit of Minimum 1% of the account Size is important to apply for a Payout request.</p>
                                <div className="legal-content">
                                    <div className="legal-heading">1. Minimum Trading days :</div>
                                    <ul className='ul-dick'>
                                        <li>To complete each stage of our evaluation, you must trade on at least 5 separate days. A trading day is recorded when you execute at least one trade, regardless of how long it’s held. Holding trades overnight won’t count as additional trading days.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">2. Profit Target:</div>
                                    <ul className='ul-dick'>
                                        <li>Achieve the 8% profit target for P1 and 5% profit target for P2 by closing all your trades and following the trading rules within the designated time period to demonstrate your trading skills.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">3. Understanding Daily Drawdown Rules (Trailing Balance)</div>
                                    <ul className='ul-dick'>
                                        <li>The daily drawdown limit is the most you can lose in a day, set at 5% of your trailing balance (previous day's balance + booked profits).</li>
                                        <li>For example, with a $500.00 account balance, the limit is 5%, or $25.00. If you make a $8.00 profit on Day 1, your balance becomes $508.00, and the new limit for Day 2 is 5% of $508.00 or $25.40.</li>
                                        <li>After earning $23.00 more on Day 2, your balance updates to $531.00, raising the limit to $26.55.</li>
                                        <li>But if your losses on Day 3 exceed the $26.55 limit, your account will be disabled. Always track both running and booked losses to stay within limits.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">4. Understanding Overall Drawdown Rules (Trailing Balance)</div>
                                    <ul className='ul-dick'>
                                        <li>The overall drawdown limit is the maximum amount you can lose before your account is disabled, set at 10% of the trailing balance until it goes below initial balance.</li>
                                        <li>On Day 1, you make a $20.00 profit, increasing your balance to $520.00. The drawdown limit adjusts to 10% of $520.00, which is $52.00.</li>
                                        <li>On Day 2, you lose $15.00 (&lt; $52.00). Your account remains active, and the new drawdown limit is 10% of $505.00 ($50.50).</li>
                                        <li>On Day 3, you lose another $15.00. Your balance drops to $490.00. Now, for all your trades, your max drawdown is 10% of $500.00 ($50.00), until you close a day in profit, and exceeding $50.00 overall loss will lead to disablement.</li>
                                        <li>The overall drawdown rule applies to both running and closed trades, so monitor your balance carefully.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">5. Risk Management & Discipline :</div>
                                    <ul className='ul-dick'>
                                        <li>Challenge Account: No single trade should contribute to more than 30% of the total profit target. Real Account: This applies to the requested payout amount.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">5(1). Violation Consequences :</div>
                                    <ul className='ul-dick'>
                                        <li>Any breach of the established trading rules will lead to an immediate account suspension and permanent disablement.</li>
                                    </ul>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Rationale :</div>
                                    <p>This rule encourages traders to maintain steady and predictable trading volumes, which helps in managing risk and ensuring that profits are earned through consistent trading behavior. It also offers room for growth, allowing traders to scale their volumes over time while maintaining discipline.</p>
                                </div>
                                <div className="legal-content">
                                    <div className="legal-heading">Please note :</div>
                                    <p>Hitting either the daily loss limit or the maximum loss limit will result in a permanent account breach.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
